/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.ViewAttendanceRegistrationController
 * @description This controller is used for view attendance requests and voting
 *              instructions in Agent workstation
 * @requires generalMeetingService is the service which is being used to
 *           interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires PaginationSvc is used for pagination
 * @requires userGroupService to fetch issuer logo
 */
'use strict';

angular
  .module('generalMeetingHomeModule')
  .controller('ViewAttendanceRegistrationController', [
    '$scope',
    '$sessionStorage',
    'generalMeetingService',
    '$log',
    'PaginationSvc',
    '$stateParams',
    'userGroupService',
    '$state',
    '$element',
    'cbpToast',
    function (
      $scope,
      $sessionStorage,
      generalMeetingService,
      $log,
      PaginationSvc,
      $stateParams,
      userGroupService,
      $state,
      $element,
      cbpToast
    ) {
      $scope.currentShareClass = $stateParams.shareclassId;
      $scope.meetingId = $stateParams.meetingId;
      $scope.status = $stateParams.status;
      $scope.meetingCompleted = ($stateParams.status === 'M');
      $scope.gmDateTime = $stateParams.gmDateTime;
      $scope.votingInstructionSearch = false;
      $scope.searchRegistrationData = '';
      $scope.participationMethodPassed = $stateParams.participationMethod !== null ?
          $stateParams.participationMethod : 'ALL' ;
      $scope.issuerVc = $stateParams.issuerVc;
      $scope.votingSearchButtonDisabled = false;
      if ($stateParams.landingThrough === appConstant.VOTECOLLECTOR_TAB) {
        $sessionStorage.currentTab = $stateParams.landingThrough;
      } else {
        $sessionStorage.currentTab = appConstant.REGISTRATIONS;
      }
      $scope.landingThrough = $stateParams.landingThrough;
      $scope.type = $stateParams.type;
      var pageNumber = 1;
      //Tab highlighting issue fixed - SFCBP-2312
      $scope.userType = $sessionStorage.usertype;
      $scope.selectedIndex = 1;
      if ($scope.userType === 'VC') {
        $scope.selectedIndex = 0;
      }

      $scope.shareClass = $sessionStorage.gmShareclassList;
      $scope.shareClassOptions = [
        ...$scope.shareClass.map((sc) => ({
          name: sc.shareClassName,
          value: sc.shareClassId,
        })),
      ];
      $scope.selectedShareClass =
          $scope.shareClass.length === 1
              ? $scope.shareClass[0].shareClassId
              : 'all';
      $scope.selectedShareClass = $stateParams.participationMethod === null ?
          $scope.shareClass[0].shareClassId : $scope.currentShareClass;
      $scope.currentShareClass = $scope.selectedShareClass;

      $scope.handleShareClassChange = function (ev) {
        const {value} = ev.detail;
        $scope.selectedShareClass = value;
        $scope.currentShareClass = value;
        if ($stateParams.type === appConstant.VOTING_INSTRUCTION) {
          $scope.fetchVotingInstructions();
        }
      };

      $scope.canEditNumberOfShares = function (id) {
        return id === $scope.instructionToBeUpdated;
      };

      // below is the function call to get issuer Logo
      $scope.getIssuerLogo = function (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer
            // logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      //get the count of VI, issuer name, shareclass name, gm name, gm date etc
      $scope.fetchVotingInstructions = function () {
        $scope.loading = true;
        generalMeetingService
          .getCountOfVI(
            $scope.meetingId,
            $scope.currentShareClass,
            $scope.landingThrough
          )
          .then(
            function (response) {
              $scope.loading = false;
              $scope.getIssuerLogo(response.data.issuerId);
              $scope.meetingName = response.data.meetinName;
              $scope.issuerName = response.data.issuerName;
              $scope.gmDate = response.data.meetingDate;
              $scope.shareClassName = response.data.shareClassName;
              $scope.totalRecordsPage = response.data.novInstruction;
              $scope.totalRecordCount = $scope.totalRecordsPage;
              $scope.getVIDetails(pageNumber);
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
          );
      };

      // Below function is used for pagination
      $scope.setPage = function (page) {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager(
          $scope.responseItems.length,
          page
        );
        // get current page of items
        $scope.items = $scope.responseItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };

      // Below function is used for custom pagination
      $scope.setCustomPage = function (page, parameter) {
        // Below is the function to set pagination
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        if (page !== pageNumber) {
          pageNumber = page;
          if (parameter === appConstant.VOTING_DETAILS) {
            $scope.getVIDetails(pageNumber);
          } else if (parameter === appConstant.SEARCH_VIDETAILS) {
            $scope.searchVotinginstructionRegistration(
              $scope.searchRegistrationData,
              pageNumber
            );
          } else if (parameter === appConstant.ATTENDANCE) {
            $scope.getAllAttendanceRequests($scope.searchRegistrationData, pageNumber);
          }
        }

        // Update pager widget to select the correct page number
        if ($scope.totalRecordCount) {
          $scope.pager = PaginationSvc.GetPager(
            $scope.totalRecordCount,
            pageNumber
          );
        } else {
          $scope.pager = [];
        }

        // get current page of items
        if ($scope.responseItems) {
          $scope.items = $scope.responseItems;
        } else {
          $scope.items = [];
        }
      };

      $scope.setVirtualParticipationPage = function (page) {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        $scope.searchVirtualParticipations(
          $scope.virtualParticipationSearchString,
          page
        );
      };

      //get the VI details
      $scope.getVIDetails = function (pageNumber) {
        $scope.parameter = appConstant.VOTING_DETAILS;
        $scope.loading = true;
        $scope.votingSearchButtonDisabled = true;
        const participationMethods =
            $scope.participationMethodPassed ==='ALL' ? 'PRXY,EVOT' :
                $scope.participationMethodPassed;
        generalMeetingService
        .searchVotingInstructionRegistration(
            $scope.currentShareClass,
            $stateParams.meetingId,
            pageNumber,
            appConstant.PAGE_SIZE,
            participationMethods,
            null,
            'secureToken'
        )
        .then(
            function (response) {
              $scope.totalRecordsPage = response.data.pageSize;
              $scope.totalRecordCount = response.data.totalElements;
              $scope.responseItems = response.data.list;
              $scope.pager = {};
              $scope.setCustomPage(pageNumber, $scope.parameter);
              $scope.loading = false;
              $scope.votingSearchButtonDisabled = false;
            },
            function (error) {
              $scope.loading = false;
              $scope.votingSearchButtonDisabled = false;
              $log.debug(error);
            }
        );
      };

      // Below function is used to search the Attendance
      // requests
      $scope.searchAttendanceRegistration = function (searchRegistrationData) {
        $scope.searchRegistrationData = searchRegistrationData;
        $scope.getAllAttendanceRequests(searchRegistrationData);
      };

      // Below function is used to search the voting
      // instructions
      $scope.searchVotinginstructionRegistration = function (
        searchRegistrationData,
        pageNumber
      ) {
        $scope.loading = true;
        $scope.parameter = appConstant.SEARCH_VIDETAILS;
        $scope.searchRegistrationData = searchRegistrationData;
        //to check if the previous search text is same as current search text. If NO, then clear the previous list.
        if (searchRegistrationData !== $scope.previousSearch) {
          $scope.previousSearch = $scope.searchRegistrationData;
          $scope.searchVIList = [];
        }
        if (
          searchRegistrationData &&
          searchRegistrationData.trim().length > 0
        ) {
          const participationMethods =
              $scope.participationMethodPassed ==='ALL' ? 'PRXY,EVOT' :
                  $scope.participationMethodPassed;
          generalMeetingService
          .searchVotingInstructionRegistration(
              $scope.currentShareClass,
              $scope.meetingId,
              pageNumber,
              appConstant.PAGE_SIZE,
              participationMethods,
              searchRegistrationData,
              'secureToken'
          )
          .then(
              function (response) {
                $scope.loading = false;
                $scope.totalRecordCount = response.data.totalElements;
                $scope.searchVIInitialList = response.data.list;
                //following check is added to append list of records for greater than 10 records.
                if (
                    $scope.searchVIList &&
                    $scope.searchVIInitialList &&
                    $scope.searchVIInitialList.length >= appConstant.PAGE_SIZE
                ) {
                  $scope.searchVIList = $scope.searchVIList.concat(
                      $scope.searchVIInitialList
                  );
                } else {
                  $scope.searchVIList = $scope.searchVIInitialList;
                }
                $scope.responseItems = $scope.searchVIList;
                $scope.pager = {};
                $scope.setCustomPage(pageNumber, $scope.parameter);
              },
              function (error) {
                $scope.loading = false;
                $log.debug(error);
              }
          );
        } else {
          $scope.totalRecordCount = $scope.totalRecordsPage;
          $scope.getVIDetails(pageNumber);
        }
      };

      // Below function is used to search the Attendance
      // requests or Voting instructions on click of enter key

      $scope.onEnterKey = function (
        $event,
        searchRegistrationData,
        type,
        pageNumber
      ) {
        if ($event.keyCode === 13 && type === appConstant.ATTENDANCE) {
          $scope.searchAttendanceRegistration(searchRegistrationData);
        } else if ($event.keyCode === 13 && type === appConstant.VOTING) {
          $scope.searchVotinginstructionRegistration(
            searchRegistrationData,
            pageNumber
          );
        }
      };

      $scope.cancelInstruction = function (instructionType) {
        if (
          $scope.instructionToBeCancelled == null ||
          $scope.registrationNumberToBeCancelled == null
        ) {
          return;
        }

        let data = {
          encodedInstructionId: $scope.instructionToBeCancelled,
          registrationNo: $scope.registrationNumberToBeCancelled,
          instructionType: instructionType,
          encodedMeetingId: $stateParams.meetingId,
        };

        generalMeetingService.cancelInstruction(data).then(
          function (response) {
            $element[0]
              .querySelector('#cancellationModal')
              .closeModal(new Event('click'));
            $state.reload();
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      $scope.updateInstruction = function (instructionType) {
        if (
          $scope.instructionToBeUpdated == null ||
          $scope.registrationNumberToBeUpdated == null ||
          $scope.noSharesToBeUpdated == null
        ) {
          return;
        }

        let data = {
          encodedInstructionId: $scope.instructionToBeUpdated,
          registrationNo: $scope.registrationNumberToBeUpdated,
          instructionType: instructionType,
          encodedMeetingId: $stateParams.meetingId,
          numberOfShares: $scope.noSharesToBeUpdated,
        };

        generalMeetingService.updateInstruction(data).then(
          function (response) {
            $element[0]
              .querySelector('#updateModal')
              .closeModal(new Event('click'));
            $state.reload();
          },
          function (error) {
            if (error.data.errors[0].errorMessage) {
              cbpToast.show(error.data.errors[0].errorMessage, 'negative');
            } else {
              cbpToast.show('An unexpected error has occurred', 'negative');
            }
          }
        );
      };

      $scope.toggleCanEditNumberOfShares = (id) => {
        if (id === $scope.instructionToBeUpdated) {
          $scope.instructionToBeUpdated = null;
          $state.reload();
          return false;
        } else if (id && $scope.instructionToBeUpdated) {
          // in case the user tries to edit another instruction we have to show the non updated value again
          $state.reload();
        }
        $scope.instructionToBeUpdated = id;
        return true;
      };

      $scope.showModal = () => {
        const root = $element[0];
        const modal = root.querySelector('#cancellationModal');
        modal.openModal();
      };

      $scope.showUpdateModal = () => {
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.openModal();
      };

      $scope.closeModal = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#cancellationModal');
        modal.closeModal(new Event('click'));
      };

      $scope.closeUpdateModal = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.closeModal(new Event('click'));
      };

      $scope.showCancellationModal = (instructionId, registrationNumber) => {
        $scope.instructionToBeCancelled = instructionId;
        $scope.registrationNumberToBeCancelled = registrationNumber;
        const root = $element[0];
        const modal = root.querySelector('#cancellationModal');
        modal.openModal();
      };

      $scope.showUpdateNoSharesModal = (
        instructionId,
        registrationNumber,
        noShares
      ) => {
        $scope.instructionToBeUpdated = instructionId;
        $scope.registrationNumberToBeUpdated = registrationNumber;
        $scope.noSharesToBeUpdated = noShares;
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.openModal();
      };

      $scope.closeCancellationModal = () => {
        $scope.instructionToBeCancelled = null;
        $scope.registrationNumberToBeCancelled = null;
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#cancellationModal');
        modal.closeModal(new Event('click'));
      };

      $scope.closeUpdateNoSharesModal = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.closeModal(new Event('click'));
      };

      $scope.getAllAttendanceRequests = function (searchData = null, pageNumber = 1) {
        $scope.loading = true;
        generalMeetingService.searchAttendanceRequestRegistration(
            $stateParams.shareclassId,
            $stateParams.meetingId,
            pageNumber,
            appConstant.PAGE_SIZE,
            searchData
        ) .then(
            function (response) {
              $scope.totalRecordCount = response.data.totalElements;
              $scope.responseItems = response.data.list;
              $scope.pager = {};
              $scope.setCustomPage(pageNumber, $scope.parameter);
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
        );
      };

      $scope.searchVirtualParticipations = function (searchString, page) {
        $scope.loading = true;
        $scope.virtualParticipationSearchString = searchString;
        generalMeetingService
          .getRegisteredVirtualParticipations(
            $stateParams.shareclassId,
            $stateParams.meetingId,
            page,
            searchString,
            $scope.userType
          )
          .query()
          .$promise.then(
            function (response) {
              $scope.items = response.list;
              $scope.pager = PaginationSvc.GetPager(
                response.totalElements,
                page,
                response.pageSize
              );
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
          );
      };

      $scope.getAllVirtualParticipations = function () {
        $scope.searchVirtualParticipations(null, 1);
      };

      // Below function is used to get Attendance requests or
      // Voting instruction details based on the radio button selection

      if ($stateParams.type === appConstant.ATTENDANCE) {
        $scope.getAllAttendanceRequests();
      } else if ($stateParams.type === appConstant.VOTING_INSTRUCTION) {
        $scope.fetchVotingInstructions();
      } else {
        // virtualParticipation
        $scope.getAllVirtualParticipations();
      };

      /**
       * Navigate to voting instruction details page where the user can view the voting pattern
       *
       * @param shareholderDetails
       */
      $scope.votingPatternDetails = function (votingInstruction) {
        $state.go('votingPattern', {
          shareholderDetails: votingInstruction,
          issuerLogo: $scope.issuerGroupDetails.logoBaseStr,
          selectedIndex: $scope.selectedIndex,
        });
      };

      $scope.votingPatternViewAllowed = function (votingInstruction) {
        if ($scope.userType === 'AG') {
          // Agents can view all voting instructions
          return true;
        }

        if ($scope.userType === 'VC') {
          // The visible voting instructions are already filtered by the backend
          return true;
        }

        if ($scope.userType === 'IS') {
          if ($scope.meetingCompleted === true) {
            // All votes are visible to issuer users after meeting completion
            return true;
          }
          if (votingInstruction.participationMethod === 'EVOT') {
            // All EVOTs are visible to all issuer users
            return true;
          }
          if (votingInstruction.participationMethod === 'PRXY') {
            if ($scope.issuerVc === true) {
              // only show PRXY that are assigned to the issuer
              return votingInstruction.voteCollectorType === 'IS';
            }
            return false;
          }
        }


      };

      // Below is the method to change accordion icon - SFCBP-2312
      $scope.showHideDetails = function (attendance) {
        attendance.showDetails = !attendance.showDetails;
      };
    },
  ]);
