/**
 * @name generalMeetingHomeModule
 * @description This is a module used for general meeting related modules
 * @requires $state, $stateProvider, $urlRouterProvider
 * @requires 'ui.router', 'commonModule', 'ngResource'
 */
'use strict';
angular
  .module('generalMeetingHomeModule', [
    'ui.router',
    'ngStorage',
    'ngResource',
    'gmModal',
    'vpComponentsModule',
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('generalMeetingHome', {
          url: '/generalMeetingHome',
          params: {
            selectedIndex: 1,
          },
          data: {
            nameList: ['label_generalMeeting1'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/general-meeting-home.html',
              controller: 'GeneralMeetingController',
            },
          },
        })
        .state('agentGeneralMeetingHome', {
          url: '/agentGeneralMeetingHome',
          params: {
            selectedIndex: 1,
          },
          data: {
            nameList: ['label_generalMeeting1'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/general-meeting-home.html',
              controller: 'GmListController',
            },
          },
        })
        .state('votingInfo', {
          url: '/votingInfo',
          params: {
            selectedIndex: 1,
            type: null,
            gmId: null,
            issuerId: null,
            issuerName: null,
            intermediaryDateTimeStamp: null,
            intermediaryDate: null,
            intermediaryAccessDate: null,
            intermediaryAccessDateStr: null,
            newEndDateTimeStamp: null,
            gmDate: null,
            langCode: null,
            gmStatus: null,
            allExtnReqRejected: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_voting_instruction'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request-meeting-info.html',
              controller: 'AttendanceRequestMeetingController',
            },
          },
          resolve: {
            extReqResults: [
              'ExtensionRequestService',
              '$stateParams',
              '$sessionStorage',
              function (
                ExtensionRequestService,
                $stateParams,
                $sessionStorage
              ) {
                return ExtensionRequestService.getExtensionReqStatus(
                  $stateParams.gmId,
                  $sessionStorage.groupId
                )
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('votingInfoHome', {
          url: '/votingInfoHome',
          params: {
            selectedIndex: 1,
            type: null,
            gmId: null,
            issuerId: null,
            issuerName: null,
            intermediaryDateTimeStamp: null,
            intermediaryDate: null,
            intermediaryAccessDate: null,
            intermediaryAccessDateStr: null,
            newEndDateTimeStamp: null,
            gmDate: null,
            langCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_home', 'label_voting_instruction'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request-meeting-info.html',
              controller: 'AttendanceRequestMeetingController',
            },
          },
          resolve: {
            extReqResults: [
              'ExtensionRequestService',
              '$stateParams',
              '$sessionStorage',
              function (
                ExtensionRequestService,
                $stateParams,
                $sessionStorage
              ) {
                return ExtensionRequestService.getExtensionReqStatus(
                  $stateParams.gmId,
                  $sessionStorage.groupId
                )
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('attendanceReqMeetingInfo', {
          url: '/attendanceReqMeetingInfo',
          params: {
            selectedIndex: 1,
            type: null,
            gmId: null,
            issuerId: null,
            issuerName: null,
            intermediaryDateTimeStamp: null,
            intermediaryDate: null,
            intermediaryAccessDate: null,
            intermediaryAccessDateStr: null,
            newEndDateTimeStamp: null,
            gmDate: null,
            langCode: null,
            gmStatus: null,
            allExtnReqRejected: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_attendance_requests'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request-meeting-info.html',
              controller: 'AttendanceRequestMeetingController',
            },
          },
          resolve: {
            extReqResults: [
              'ExtensionRequestService',
              '$stateParams',
              '$sessionStorage',
              function (
                ExtensionRequestService,
                $stateParams,
                $sessionStorage
              ) {
                return ExtensionRequestService.getExtensionReqStatus(
                  $stateParams.gmId,
                  $sessionStorage.groupId
                )
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('attendReqInfoHome', {
          url: '/attendReqInfoHome',
          params: {
            selectedIndex: 1,
            type: null,
            gmId: null,
            issuerId: null,
            issuerName: null,
            intermediaryDateTimeStamp: null,
            intermediaryDate: null,
            intermediaryAccessDate: null,
            intermediaryAccessDateStr: null,
            newEndDateTimeStamp: null,
            gmDate: null,
            langCode: null,
            breadCrumbDetails: [],
          },
          data: {
            nameList: ['label_home', 'label_attendance_requests'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request-meeting-info.html',
              controller: 'AttendanceRequestMeetingController',
            },
          },
          resolve: {
            extReqResults: [
              'ExtensionRequestService',
              '$stateParams',
              '$sessionStorage',
              function (
                ExtensionRequestService,
                $stateParams,
                $sessionStorage
              ) {
                return ExtensionRequestService.getExtensionReqStatus(
                  $stateParams.gmId,
                  $sessionStorage.groupId
                )
                  .query()
                  .$promise.then(
                    function (data) {
                      return data;
                    },
                    function (error) {
                      return error;
                    }
                  );
              },
            ],
          },
        })
        .state('inviteSharePlan', {
          url: '/inviteSharePlan',
          params: {
            selectedIndex: 1,
            meetingStatus: null,
            issuerId: null,
            gmDate: null,
            issuerName: null,
            documents: null,
            emailBody: null,
            emailSubject: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/invite-shareplan/invite-share-plan.html',
              controller: 'InviteSharePlanController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_invite_share_plan'],
          },
        })
        .state('notification', {
          url: '/gmNotification',
          params: {
            selectedIndex: 1,
            meetingId: null,
            breadCrumbDetails: [],
            issuerId: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/gm-notification/gm-notification.html',
              controller: 'GeneralMeetingNotificationController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_notification_overview'],
          },
        })
        .state('createExtensionRequest', {
          url: '/createExtensionRequest',
          params: {
            selectedIndex: 1,
            issuerId: null,
            gmName: null,
            generalMeetingId: null,
            gmDateTime: null,
            userType: null,
            gmStatus: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/extension-request/view-extension-reqs-meetinglevel.html',
              controller: 'ViewExtController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_extReq'],
          },
        })
        .state('createIssuerExtensionRequest', {
          url: '/createIssuerExtensionRequest',
          params: {
            selectedIndex: 1,
            issuerId: null,
            gmName: null,
            generalMeetingId: null,
            gmDateTime: null,
            userType: null,
            gmStatus: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/extension-request/view-issuer-extension-requests.html',
              controller: 'ViewIssuerExtController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_extReq'],
          },
        })
        .state('extensionRequest', {
          url: '/extensionRequest',
          params: {
            selectedIndex: 1,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/extension-request/dwt-view-extension.html',
              controller: 'ExtensionRequestController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_extReq'],
          },
        })
        .state('bulkUploadAttendReq', {
          url: '/bulkUploadAttendReq',
          params: {
            selectedIndex: 1,
            gmId: null,
            issuerId: null,
            type: null,
            issuerName: null,
            gmDate: null,
            langCode: null,
            gmName: null,
          },
          views: {
            mainView: {
              templateUrl: 'features/modules/bulk-upload/bulk-upload-ar.html',
              controller: 'BulkUploadController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_attendance_requests',
              'label_bulk_upload_attendance_requests',
            ],
          },
        })
        .state('bulkUploadVI', {
          url: '/bulkUploadVI',
          params: {
            selectedIndex: 1,
            gmId: null,
            issuerId: null,
            type: null,
            issuerName: null,
            gmDate: null,
            langCode: null,
            gmName: null,
          },
          views: {
            mainView: {
              templateUrl: 'features/modules/bulk-upload/bulk-upload-vi.html',
              controller: 'BulkUploadController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_bulk_upload_voting_instructions',
            ],
          },
        })
        .state('viewVotingAttendanceReq', {
          url: '/viewVotingAttendanceReq',
          params: {
            selectedIndex: 1,
            attendanceReqId: null,
            status: null,
            gmId: null,
            issuerId: null,
            issuerName: null,
            gmDate: null,
            intermediaryDate: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-attendance-request.html',
              controller: 'ViewAttendanceRequestController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_attendance_requests',
              'label_view_attendance_requests',
            ],
          },
        })
        .state('gmList', {
          url: '/generalMeetingList',
          params: {
            selectedIndex: 0,
          },
          data: {
            nameList: ['label_generalMeeting1'],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-details/general-meeting-list.html',
              controller: 'sharePlanHolderController',
            },
          },
        })
        .state('votingInstructions', {
          url: '/votingInstructions',
          params: {
            selectedIndex: 1,
            data: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            issuerName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            breadCrumbDetails: [],
            virtualMeetingFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction.html',
              controller: 'VotingInstructionController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_newVote',
            ],
          },
        })
        .state('votingInstructionsIntermediary', {
          url: '/votingInstructionsIntermediary',
          params: {
            selectedIndex: 1,
            data: null,
            issuerName: null,
            meetingId: null,
            issuerId: null,
            shareClassName: null,
            shareHolderId: null,
            sharePlanloginShareClassId: null,
            votingInstDetails: null,
            instructionId: null,
            fromPage: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/voting-instruction/voting-instruction-intermediary.html',
              controller: 'VotingInstructionIntermediaryController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_newVote',
            ],
          },
        })
        .state('generalMeetings', {
          url: '/generalMeetings',
          params: {
            selectedIndex: 1,
            meetingId: null,
            issuerId: null,
            issuerName: null,
            status: null,
            intermediaryDateTimeStamp: null,
            intermediaryDate: null,
            showHideFlag: null,
            flag: null,
            newEndDateTime: null,
            allExtnReqRejected: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-details/general-details.html',
              controller: 'GeneralDetailsViewController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_generalDetails'],
          },
        })
        .state('attendanceRequest', {
          url: '/attendanceRequest',
          params: {
            selectedIndex: 1,
            data: null,
            issuerId: null,
            issuerName: null,
            gmId: null,
            sharePlanId: null,
            attendanceDataDeatils: null,
            fromPage: null,
            gmDate: null,
            spVoteEnabled: null,
            breadCrumbDetails: [],
            virtualMeetingFlag: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/attendance-request.html',
              controller: 'AttendanceRequestController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_attendance_requests',
              'label_newRegistration',
            ],
          },
        })
        .state('createRightToSpeak', {
          url: '/createRightToSpeak',
          params: {
            selectedIndex: 1,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassName: null,
            intermediaryId: null,
            instructionId: null,
            userType: null,
            numberOfSecurities: null,
            isin: null,
            shareclassId: null,
            issuerName: null,
            type: null,
            rightToSpeakForVote: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/right-to-speak-AR.html',
              controller: 'RightToSpeakController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_createRTS',
            ],
          },
        })
        .state('editVIRightToSpeak', {
          url: '/editVIRightToSpeak',
          params: {
            selectedIndex: 1,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassName: null,
            intermediaryId: null,
            instructionId: null,
            userType: null,
            numberOfSecurities: null,
            isin: null,
            shareclassId: null,
            type: null,
            editRTS: true,
            rightToSpeakForVote: null,
            attendanceRequestId: null,
            registrationNo: null,
            issuerName: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/right-to-speak-AR.html',
              controller: 'RightToSpeakController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_voting_instruction',
              'label_editRTS',
            ],
          },
        })
        .state('editARRightToSpeak', {
          url: '/editARRightToSpeak',
          params: {
            selectedIndex: 1,
            meetingId: null,
            shareholderId: null,
            issuerId: null,
            shareclassName: null,
            intermediaryId: null,
            instructionId: null,
            userType: null,
            numberOfSecurities: null,
            isin: null,
            shareclassId: null,
            type: null,
            editRTS: true,
            issuerName: null,
            rightToSpeakForVote: null,
            attendanceRequestId: null,
            registrationNo: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/right-to-speak-AR.html',
              controller: 'RightToSpeakController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_attendance_requests',
              'label_editRTS',
            ],
          },
        })
        .state('vcHome', {
          url: '/vcHome',
          params: {
            selectedIndex: 1,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/general-meeting-home.html',
              controller: 'GeneralMeetingHomeController',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('vcDetails', {
          url: '/vcDetails',
          params: {
            selectedIndex: 1,
            data: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/gm-general-details.html',
            },
          },
          data: {
            displayName: '',
          },
        })
        .state('vcDetails.vc', {
          url: '/voteCollector',
          views: {
            generalMeetingDetails: {
              component: 'gmVoteCollectorView',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'userMaint_voteCollector_label',
            ],
          },
        })
        .state('viewAttendance', {
          url: '/viewAttendance',
          params: {
            selectedIndex: 1,
            type: null,
            shareclassId: null,
            meetingId: null,
            status: null,
            gmDateTime: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-attendance.html',
              controller: 'ViewAttendanceRegistrationController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_registrations',
              'label_viewattendanceRequests',
            ],
          },
        })
        .state('viewVoting', {
          url: '/voting',
          params: {
            selectedIndex: 1,
            type: null,
            landingThrough: null,
            shareclassId: null,
            meetingId: null,
            status: null,
            gmDateTime: null,
            participationMethod: null
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-voting.html',
              controller: 'ViewAttendanceRegistrationController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_registrations',
              'label_viewVotingInstructions',
            ],
          },
        })
        .state('viewVirtualParticipations', {
          url: '/virtual-participations',
          params: {
            selectedIndex: 1,
            type: null,
            landingThrough: null,
            shareclassId: null,
            meetingId: null,
            status: null,
            gmDateTime: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-virtual-participations.html',
              controller: 'ViewAttendanceRegistrationController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_registrations',
              'label_attend_virtualy',
            ],
          },
        })
        .state('viewShareplan', {
          url: '/viewShareplan',
          params: {
            selectedIndex: 1,
            shareclassId: null,
            meetingId: null,
            gmDateTime: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/invite-shareplan/view-share-plan.html',
              controller: 'ViewShareplanController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_registrations',
              'label_viewSharePlanHolder',
            ],
          },
        })
        .state('resendSharePlanInvite', {
          url: '/resendSharePlanInvite',
          params: {
            selectedIndex: 1,
            selectedSharePlanList: null,
            resendEmailAddress: null,
            emailLogId: null,
            meetingId: null,
            meetingName: null,
            gmDate: null,
            issuerId: null,
            gmDateTime: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/invite-shareplan/share-plan-reinvite-send-email.html',
              controller: 'reinviteSharePlanController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_registrations',
              'label_viewSharePlanHolder',
              'label_resendInviteSharePlan',
            ],
          },
        })
        .state('viewVotingVC', {
          url: '/viewVotingVC',
          params: {
            selectedIndex: null,
            type: null,
            shareclassId: null,
            meetingId: null,
            status: null,
            gmDateTime: null,
            landingThrough: null,
            participationMethod: null,
            issuerVc: null
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-voting.html',
              controller: 'ViewAttendanceRegistrationController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'userMaint_voteCollector_label',
              'label_viewVotingInstructions',
            ],
          },
        })
        .state('votingPattern', {
          //This routing is added as part of - SFCBP-2312
          url: '/votingPattern',
          params: {
            selectedIndex: null,
            shareholderDetails: null,
            issuerLogo: null,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/view-voting-pattern.html',
              controller: 'ViewVotingPatternController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'userMaint_voteCollector_label',
              'label_viewVotingInstructions',
            ],
          },
        })
        .state('audit', {
          url: '/audit',
          params: {
            selectedIndex: 1,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/audit-trail-module/admin-audit-trail.html',
              controller: 'AuditController',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_auditAndTemplate'],
          },
        })
        .state('liveMeetingOverview', {
          url: '/liveMeetingOverview',
          params: {
            selectedIndex: 1,
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/general-meeting-module/gm-live-meeting-overview/live-meeting-overview.html',
              controller: 'liveMeetingOverviewCtrl',
            },
          },
          data: {
            nameList: ['label_generalMeeting1', 'label_liveMeetingOverview'],
          },
        })
        .state('editARBulkUpload', {
          url: '/editARBulkUpload',
          params: {
            selectedIndex: 1,
            gmId: null,
            reqId: null,
            issuerId: null,
            shareholderId: null,
            registrationNo: null,
            gmDate: null,
            status: null,
            boFileFlag: null,
            boFileName: null,
            issuerName: null,
            breadCrumbDetails: [],
          },
          views: {
            mainView: {
              templateUrl:
                'features/modules/attendance-request/edit-AR-BulkUpload.html',
              controller: 'EditARBulkUploadController',
            },
          },
          data: {
            nameList: [
              'label_generalMeeting1',
              'label_attendance_requests',
              'label_edit_IN_AR_BOFile',
            ],
          },
        });
    },
  ]);
