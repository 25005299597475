import {
  resetCheckBoxValues
} from '@/shared/utilities/general-meeting-utilities';

// method to check whether both SH and SP is selected on any shareclass,
// Size of set will be greater than 1 if both SH and SP is selected
export const validateParticipationMethodForSHandSP = function (shareClass) {
  const wrongSelection = new Set(
      shareClass.participationMethods
      .filter((participationMethod) => {
        if (
            participationMethod.canBeRequestedBy === 'SH' ||
            participationMethod.canBeRequestedBy === 'SP'
        ) {
          return participationMethod;
        }
      })
      .map((item) => item.canBeRequestedBy)
  );
  return wrongSelection.size <= 1;
};
export const prepareParticipationMethods = function (shareClasses) {
  shareClasses.forEach((shareClass) => {
    shareClass.participationMethods = [];
    if (shareClass.votingInstructionIntermediary) {
      shareClass.participationMethods.push({
        participationMethod: 'PRXY',
        canBeRequestedBy: 'IN',
        supportedChannel: 'PORTAL',
      });
      shareClass.participationMethods.push({
        participationMethod: 'PRXY',
        canBeRequestedBy: 'IN',
        supportedChannel: 'SWIFT',
      });
    }
    if (shareClass.votingInstructionShareholder) {
      shareClass.participationMethods.push({
        participationMethod: 'PRXY',
        canBeRequestedBy: 'SH',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.votingInstructionShareplanholder) {
      shareClass.participationMethods.push({
        participationMethod: 'PRXY',
        canBeRequestedBy: 'SP',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.votingByCorrespondenceShareholder) {
      shareClass.participationMethods.push({
        participationMethod: 'EVOT',
        canBeRequestedBy: 'SH',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.votingByCorrespondenceShareplanholder) {
      shareClass.participationMethods.push({
        participationMethod: 'EVOT',
        canBeRequestedBy: 'SP',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.virtualParticipationShareholder) {
      shareClass.participationMethods.push({
        participationMethod: 'VIRT',
        canBeRequestedBy: 'SH',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.virtualParticipationShareplanholder) {
      shareClass.participationMethods.push({
        participationMethod: 'VIRT',
        canBeRequestedBy: 'SP',
        supportedChannel: 'PORTAL',
      });
    }
    if (shareClass.requestAttendenceCardShareholder) {
      shareClass.participationMethods.push({
        participationMethod: 'PHYS',
        canBeRequestedBy: 'SH',
        supportedChannel: 'PORTAL',
      });
      // Intermediaries can request attendance request by default
      shareClass.participationMethods.push({
        participationMethod: 'PHYS',
        canBeRequestedBy: 'IN',
        supportedChannel: 'PORTAL',
      });
      shareClass.participationMethods.push({
        participationMethod: 'PHYS',
        canBeRequestedBy: 'IN',
        supportedChannel: 'SWIFT',
      });
    }
    if (shareClass.requestAttendenceCardShareplanholder) {
      shareClass.participationMethods.push({
        participationMethod: 'PHYS',
        canBeRequestedBy: 'SP',
        supportedChannel: 'PORTAL',
      });
    }
  });
  return shareClasses;
};
export const translateParticipationMethods = function (shareClasses) {
  shareClasses.forEach((shareClass) => {
    resetCheckBoxValues(shareClass);

    shareClass.participationMethods.forEach((pM) => {
      if (
          pM.participationMethod === 'PRXY' &&
          pM.canBeRequestedBy === 'IN'
      ) {
        shareClass.votingInstructionIntermediary = true;
      }
      if (
          pM.participationMethod === 'PRXY' &&
          pM.canBeRequestedBy === 'SH'
      ) {
        shareClass.votingInstructionShareholder = true;
      }
      if (
          pM.participationMethod === 'PRXY' &&
          pM.canBeRequestedBy === 'SP'
      ) {
        shareClass.votingInstructionShareplanholder = true;
      }
      if (
          pM.participationMethod === 'PHYS' &&
          pM.canBeRequestedBy === 'SH'
      ) {
        shareClass.requestAttendenceCardShareholder = true;
      }
      if (
          pM.participationMethod === 'PHYS' &&
          pM.canBeRequestedBy === 'SP'
      ) {
        shareClass.requestAttendenceCardShareplanholder = true;
      }
      if (
          pM.participationMethod === 'EVOT' &&
          pM.canBeRequestedBy === 'SH'
      ) {
        shareClass.votingByCorrespondenceShareholder = true;
      }
      if (
          pM.participationMethod === 'EVOT' &&
          pM.canBeRequestedBy === 'SP'
      ) {
        shareClass.votingByCorrespondenceShareplanholder = true;
      }
      if (
          pM.participationMethod === 'VIRT' &&
          pM.canBeRequestedBy === 'SH'
      ) {
        shareClass.virtualParticipationShareholder = true;
      }
      if (
          pM.participationMethod === 'VIRT' &&
          pM.canBeRequestedBy === 'SP'
      ) {
        shareClass.virtualParticipationShareplanholder = true;
      }
    });
  });
  return shareClasses;
};
