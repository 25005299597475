import getTermsOfUseUrl from '../../../lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.EditShAttendanceController
 * @description This controller is used for attendance requests. This mainly
 *              deals with editing attendance request.
 * @requires attendanceRequestService,shareHolderService are the service which is
 *           being used to interact with REST
 * @requires ModelWindowService is used for modal windows
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $filter to translate messages which are declared in locale files
 */
angular
  .module('generalMeetingHomeModule')
  .controller(
    'EditShAttendanceController',
    [
      '$scope',
      '$sessionStorage',
      '$stateParams',
      '$log',
      'attendanceRequestService',
      '$filter',
      '$timeout',
      'voteInstructionService',
      'issuerLogo',
      'ModelWindowService',
      'shareHolderService',
      'myVoteService',
      '$state',
      function($scope, $sessionStorage, $stateParams, $log,
        attendanceRequestService,$filter,
        $timeout, voteInstructionService, issuerLogo,ModelWindowService,shareHolderService,myVoteService,$state) {
        $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
        /**
                       * set language code from session storage, if not
                       * available, mark it as English
                       */
        $scope.createDefaultProxy  ='N';
        $scope.languageCode = $sessionStorage.workstationData.selectedLang
                              || 'EN';
        $scope.formLang = $scope.languageCode;
        // declare variables.
        $scope.issuerGroupDetails = issuerLogo.data;
        $scope.createProxy = false;
        $scope.selectProxy = false;
        $scope.editProxy = false;
        $scope.duplicateRequest = false;
        $scope.proxyProvided = '';
        $scope.userType = $sessionStorage.usertype;
        $scope.shareHolderVO = {};
        $scope.intermediaryName = $stateParams.intermediaryName;
        $scope.proxyDetails = [];
        $scope.proxyDefaultDetails = false;
        $scope.selectedShareClassId = null;
        $scope.loading = false;
        $scope.loadingSpin = false;
        $scope.isVirtualMeetingAllowed =  $stateParams.isVirtualMeetingAllowed;
        var attendanceRequestData={};
        $scope.eventType = 'Footer Term of Use';
        //Below code is added for ForFarmer default proxy chnages
        $scope.issuerName = $stateParams.issuerName;

        if($scope.issuerName.toLowerCase() === 'forfarmers n.v.'){
          $scope.isForFarmer = true;
        };
        var proxyForFarmers = {
          'proxyCity': 'Lochem',
          'proxyCountry': 'NLD',
          'proxyInitial': null,
          'proxyName': 'Coöperatie FromFarmers UA',
          'proxyType': 'LGL',
          'proxyTitle': null,
          'proxyId': null,
          'representativeName': 'Iedere vertegenwoordiger van de Cöoperatie FromFarmers',
          'legalIdentifier': null,
          'proxyProvided': null,
          'createNewProxy': null
        };        
        //to change the proxy in dropdown
        $scope.selectedProxy = function(selectedProxy) {
          $scope.selectedProxyType = selectedProxy;
          $scope.proxyTitle = null;
        };
        //to change the proxy title in dropdown
        $scope.onProxyTitleChange = function(selectedProxyTitle) {
          $scope.selectedProxyTitle = selectedProxyTitle;
        };
        //to change the proxy country in dropdown
        $scope.onProxyCountryChange = function(
          selectedProxyCountry) {
          $scope.selectedProxyCountry = selectedProxyCountry;
        };
        $scope.shMyAttendList = function() {
          $state.go('shMyVotes', {type:'attend',selectedIndex: 0,landingThrough:'addAttend'});
        };
        // when language changes, following piece of code is executed from header-controller.js
        $scope.$on('onLanguageChangeShareHolder', function( event, lang) {
          $scope.languageCode = lang;
          $scope.formLang = lang;
          $scope.getSubmittedDetails();
        });
        //Below method will be called when edit proxy radio button is selected
        $scope.prepopulateEditProxyValues = function(){
                        	$scope.proxyInitial = $scope.submittedBulkUploadDetails.proxyInitials;
          $scope.proxyName = $scope.submittedBulkUploadDetails.proxyName;
          $scope.proxyCity = $scope.submittedBulkUploadDetails.proxyCity;
          $scope.representativeName = $scope.submittedBulkUploadDetails.representativeName;
          $scope.legalIdentifier = $scope.submittedBulkUploadDetails.legalIdentifier;
          $scope.proxyTitle = $filter('filter')(
            $scope.attendanceData.shareHolderTitleVO, {
              title: $scope.submittedBulkUploadDetails.proxyTitle
            }, true);
          //preselect proxytype
          angular.forEach($scope.attendanceData.proxyTypeVO,
            function(item6) {
              if (angular.equals($scope.submittedBulkUploadDetails.proxyCodeType,
                item6.proxyTypeCode)) {
                $scope.selectedProxyType = item6;
              }
            });
          //preselect the country
          angular.forEach($scope.attendanceData.countryCodeVO,
            function(item4) {
              if (angular.equals($scope.submittedBulkUploadDetails.proxyCountry,
                item4.countryName)) {
                $scope.selectedCountry = item4;
              }
              if (angular.equals($scope.submittedBulkUploadDetails.proxyCountryCode,
                item4.isoCountryCode)) {
                $scope.selectedProxyCountry = item4;
              }
            });
          $scope.selectedProxyList = $filter(
            'filter')($scope.proxyDetails,
            {
              proxyName: $scope.submittedBulkUploadDetails.proxyName
            }, true);
        };
        //to submit the edited attendance request
        $scope.getSubmittedDetails = function() {
                    	  shareHolderService.getBulkUploadARDetails(
            $stateParams.reqId, $scope.formLang).query().$promise
            .then(function(data) {
              //initialize all the variables to be populated in UI
              $scope.attendanceData = data;
              //$scope.submittedBulkUploadDetails gives the submitted details while creating AR
              $scope.submittedBulkUploadDetails = data.sharePlanHolderVO;
              $scope.meetingName = $scope.attendanceData.meetingName;
              $scope.lastName = $scope.submittedBulkUploadDetails.lastName;
              $scope.contactPerson = $scope.submittedBulkUploadDetails.contactPerson;
              $scope.legalEntityIdentifier = $scope.submittedBulkUploadDetails.legalEntityIdentifier;
              $scope.emailAddress = $scope.submittedBulkUploadDetails.emailAddress;
              $scope.address = $scope.submittedBulkUploadDetails.address;
              $scope.phoneNumber = $scope.submittedBulkUploadDetails.phoneNumber;
              $scope.postalCode = $scope.submittedBulkUploadDetails.postalCode;
              $scope.city = $scope.submittedBulkUploadDetails.city;
              $scope.numberOfShares = $scope.submittedBulkUploadDetails.number;
              $scope.proxyInitial = $scope.submittedBulkUploadDetails.proxyInitials;
              $scope.intermediaryId = data.defaultIntermediaryId;
              $scope.representativeName = $scope.submittedBulkUploadDetails.representativeName;
              $scope.legalIdentifier = $scope.submittedBulkUploadDetails.legalIdentifier;
              $scope.gmMeetingName = data.meetingName;
              $scope.securityAccNo = $scope.submittedBulkUploadDetails.securityAccountNumber;
              //if the proxy was provided while creating the AR, then preselect 
              //edit proxy radio button, else select none radio button.
              if ($scope.submittedBulkUploadDetails.proxyProvided === 'Y') {
                $scope.proxyProvidedValue = 'proxyEdit';
                $scope.createProxy = false;
                $scope.selectProxy = false;
                $scope.editProxy = 'Y';
                $scope.createNewProxy = 'N';
                $scope.proxyProvided = 'Y';
                $scope.prepopulateEditProxyValues();
              } else {
                $scope.selectProxy = false;
                $scope.editProxy = 'N';
                $scope.proxyProvidedValue = 'none';
                $scope.createProxy = false;
                $scope.proxyProvided = 'N';
                $scope.createNewProxy = 'N';
                $scope.proxyInitial = $scope.submittedBulkUploadDetails.proxyInitials;
              }

              // if the shareclass is more than one go into this IF condition
              if ($scope.attendanceData.shareClassISINVO.length > 1) {
                $scope.moreThanOneShareClass = true;
                angular.forEach($scope.attendanceData.shareClassISINVO,
                  function(item) {
                    //preselect the submitted shareclass detail in the dropdown.
                    if (angular.equals(
                      $scope.submittedBulkUploadDetails.shareclassName,item.shareClassName)) {
                      $scope.selectedShareClass = item;
                      $scope.isin = $scope.selectedShareClass.isin;
                    }
                  });
              } else {
                //if there is only one shareclass, do not show dropdown. Show the shareclass 
                //selected while creating AR
                $scope.moreThanOneShareClass = false;
                $scope.selectedShareClass = $scope.attendanceData.shareClassISINVO[0];
                $scope.isin = $scope.attendanceData.shareClassISINVO[0].isin;
              }
              if($scope.submittedBulkUploadDetails.number === 0 || $scope.submittedBulkUploadDetails.number === '0'){
                                        	  $scope.holding = 'entire';
              }else{
                                        	  $scope.holding = 'partial';
              }
              //preselect the ownetype with the submitted owne type
              angular.forEach($scope.attendanceData.owneTypeVO,
                function(item2) {
                  if (angular.equals($scope.submittedBulkUploadDetails.owneType,
                    item2.userType)) {
                    $scope.selectedOwneType = item2;
                  }
                });
              //preselect the proxy type with the submitted proxy type
              angular.forEach($scope.attendanceData.proxyTypeVO,
                function(item3) {
                  if (angular.equals($scope.submittedBulkUploadDetails.shareHolderType,
                    item3.proxyType)) {
                    $scope.selectedShareholderType = item3;
                  }
                });
            }, function(error) {
              $log.debug(error);
            });
        };

        $scope.onProxyNameChange = function(selectedProxyList) {
                    	  $scope.selectedProxyInitial = selectedProxyList[0].proxyInitial;
          $scope.selectedProxyCountry = $filter('filter')($scope.attendanceData.countryCodeVO,
            {isoCountryCode: selectedProxyList[0].proxyCountry
            }, true);
          $scope.selectedProxyRepresentativeName = selectedProxyList[0].representativeName;
          $scope.selectedProxyLegalIdentifier = selectedProxyList[0].legalIdentifier;
          $scope.selectedProxyCity = selectedProxyList[0].proxyCity;
          $scope.selectedProxyTitle = $filter('filter')(
            $scope.attendanceData.shareHolderTitleVO, {
              title: selectedProxyList[0].proxyTitle
            }, true);
          $scope.selectedProxyCode = $filter('filter')($scope.attendanceData.proxyTypeVO,
            {proxyTypeCode: $scope.selectedProxyList[0].proxyType
            }, true);
        };
        // Below is the function call on selection radio buttons
        // to hide and show particular section

        $scope.selectedRadio = function(value) {
          switch (value) {
          case 'proxyCreate':
            $scope.createProxy = true;
            $scope.selectProxy = false;
            $scope.editProxy = 'N';
            $scope.createNewProxy = 'Y';
            $scope.proxyProvidedValue = value;
            $scope.proxyProvided = 'Y';
            $scope.proxyName = null;
            $scope.proxyCity = null;
            $scope.proxyInitial = null;
            $scope.representativeName = null;
            $scope.legalIdentifier = null;
            $scope.selectedProxyType = null;
            $scope.selectedProxyCountry = null;
            $scope.proxyTitle = null;
            //To select country by default
            angular.forEach($scope.attendanceData.countryCodeVO,
              function(item4) {
                if (angular.equals('NLD',
                  item4.isoCountryCode)) {
                  $scope.onProxyCountryChange(item4);
                }
              });
            break;
          case 'proxyEdit':
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.editProxy = 'Y';
            $scope.createNewProxy = 'N';
            $scope.proxyProvided = 'Y';
            $scope.proxyProvidedValue = value;
            $scope.prepopulateEditProxyValues();
            break;
          case 'proxySelect':
            $scope.createProxy = false;
            $scope.selectProxy = true;
            $scope.editProxy = 'N';
            $scope.createNewProxy = 'N';
            $scope.proxyProvided = 'Y';
            $scope.proxyProvidedValue = value;
            //Below code is added for ForFarmer default proxy chnages
            if($scope.isForFarmer){
              $scope.selectedProxyList = $filter('filter')($scope.proxyDetails,
                {proxyName: 'Coöperatie FromFarmers UA'
                }, true);
            }else {
              //Below changes are added to remove default proxy value for shareholder WS
              $scope.selectedProxyList = [$scope.proxyDetails[0]];
            }
            $scope.onProxyNameChange($scope.selectedProxyList);
            break;
          default:
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.editProxy = 'N';
            $scope.proxyProvided = 'N';
            $scope.createNewProxy = 'N';
            $scope.proxyProvidedValue = 'none';
            break;
          }
        };
        //to be checked. This has to be used to fetch the details of proxy
        //available for a shareholder. Unsure about its usage for intermediary
        shareHolderService
          .getAvailableProxyList($sessionStorage.groupId)
          .then(
            function(response) {
              //Some code is removed from this block to remove default proxy value for shareholder WS
              $scope.proxyDetails = response.data;
              if($scope.isForFarmer){
                if (response.data.length === 0) {
                  $scope.proxyDetails = $scope.proxyDetails
                    .concat(proxyForFarmers);
                } else {
                  $scope.checkForDefualtProxy = $filter(
                    'filter')(response.data, {
                    proxyName: 'Coöperatie FromFarmers UA'
                  }, true);
                  if ($scope.checkForDefualtProxy.length === 0) {
                    $scope.proxyDetails = $scope.proxyDetails
                      .concat(proxyForFarmers);
                  }
                }
                $scope.selectedProxyList = $filter(
                  'filter')($scope.proxyDetails,
                  {
                    proxyName: 'Coöperatie FromFarmers UA'
                  }, true);
                $scope.selectedProxyCity = 'Lochem';
              }
            }, function(error) {
              $log.debug(error);
            });

        // Below is the function to submit attendance request

        $scope.submitAttendanceRequest = function() {
          $scope.response = null;
          $scope.error = null;
          $scope.errorCode = null;
          $scope.loadingSpin = true;
          $scope.proxyTitle = null;
          if ($scope.editProxy === 'Y' || ($scope.createNewProxy === 'Y' && !$scope.selectProxy)) {
            $scope.isoCountrycode = $scope.selectedProxyCountry.isoCountryCode;
            $scope.proxyTypeCode = $scope.selectedProxyType.proxyTypeCode;
          }
          if($scope.selectedProxyTitle && !$scope.selectProxy) {
            $scope.proxyTitle = $scope.selectedProxyTitle[0].title;
          } else if($scope.proxyTitle && $scope.proxyTitle[0].title){
                        	$scope.proxyTitle = $scope.proxyTitle[0].title;
          }
          if($scope.holding === 'entire'){
                        	$scope.numberOfShares = 0;
          }
          if($scope.selectProxy && $scope.selectedProxyList && !$scope.selectedProxyList[0].proxyId){
            $scope.proxyProvided = 'N';
            $scope.createNewProxy = 'Y';
            $scope.createDefaultProxy = 'Y';
          }else{
                        	$scope.createDefaultProxy ='N';
          }
          if ($scope.proxyProvided === 'Y'
                            && $scope.editProxy === 'Y') {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              attendanceId: $stateParams.reqId,
              registrationNo: $stateParams.registrationNo,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest : $scope.intRequestId,
              gmDate: $stateParams.gmDate,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                shareholderId: $sessionStorage.groupId,
                shareclassId: $scope.selectedShareClass.shareclassId,
                isin: $scope.isin,
                isRegisterShareclass: $scope.selectedShareClass.isRegisterShareclass,
                proxyCity: $scope.proxyCity,
                proxyCountry: $scope.isoCountrycode,
                proxyInitials: $scope.proxyInitial,
                proxyName: $scope.proxyName,
                proxyType: $scope.proxyTypeCode,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.representativeName,
                legalIdentifier: $scope.legalIdentifier,
                proxyId: $scope.selectedProxyList[0].proxyId,
                proxyEdit : 'Y'
              }]
            };
          }else if ($scope.proxyProvided === 'Y'
                            && $scope.createNewProxy === 'Y') {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.gmId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              attendanceId: $stateParams.reqId,
              registrationNo: $stateParams.registrationNo,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest : $scope.intRequestId,
              gmDate: $stateParams.gmDate,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                shareholderId: $sessionStorage.groupId,
                shareclassId: $scope.selectedShareClass.shareclassId,
                isin: $scope.isin,
                isRegisterShareclass: $scope.selectedShareClass.isRegisterShareclass,
                proxyCity: $scope.proxyCity,
                proxyCountry: $scope.isoCountrycode,
                proxyInitials: $scope.proxyInitial,
                proxyName: $scope.proxyName,
                proxyType: $scope.proxyTypeCode,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.representativeName,
                legalIdentifier: $scope.legalIdentifier
              }]
            };
          } else if ($scope.selectProxy) {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.gmId,
              langCode:$scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              attendanceId: $stateParams.reqId,
              registrationNo: $stateParams.registrationNo,
              isin: $scope.isin,
              isRegisterShareclass: $scope.selectedShareClass.isRegisterShareclass,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest : $scope.intRequestId,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                shareholderId: $sessionStorage.groupId,
                shareclassId: $scope.selectedShareClass.shareclassId,
                isin: $scope.isin,
                proxyCity: $scope.selectedProxyCity,
                proxyCountry: $scope.selectedProxyCountry[0].isoCountryCode,
                proxyInitials: $scope.selectedProxyInitial,
                proxyName: $scope.selectedProxyList[0].proxyName,
                proxyType: $scope.selectedProxyCode[0].proxyTypeCode,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.selectedProxyRepresentativeName,
                legalIdentifier: $scope.selectedProxyLegalIdentifier,
                proxyId: $scope.selectedProxyList[0].proxyId,
                createDefaultProxy:$scope.createDefaultProxy
              }]
            };
          } else {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.gmId,
              langCode:$scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              attendanceId: $stateParams.reqId,
              registrationNo: $stateParams.registrationNo,
              isin: $scope.isin,
              isRegisterShareclass: $scope.selectedShareClass.isRegisterShareclass,
              initiatedThruShrhWrkst: 'Y',
              noOfSecurities: $scope.numberOfShares,
              intermediaryAdditionRequest : $scope.intRequestId,
              gmDate: $stateParams.gmDate,
              securityAccNo: $scope.securityAccNo,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: 'N',
                createNewProxy: 'N',
                shareholderId: $sessionStorage.groupId,
                shareclassId: $scope.selectedShareClass.shareclassId,
                isin: $scope.isin
              }]
            };
          }
          //submit AR request
          shareHolderService
            .saveAttendanceRequestShareholder(attendanceRequestData,
              'secureToken')
            .then(
              function(response) {
                $log.debug(response.data.message);
                $scope.loadingSpin = false;
                if ($scope.selectedShareClass.isRegisterShareclass) {
                  $scope.shMyAttendList();
                } else {
                  var phoneNo = '';
                  var intermediaryName = '';
                  var intEmailAdress = '';
                  var identificationNumber = response.data.intermediaryVO.identificationNumber;
                  $scope.emailNotificationVo= {
                    meetingId: $stateParams.gmId,
                    instructionId: response.data.intermediaryVO.requestId,
                    shareholderId: $sessionStorage.groupId,
                    instructionType: 'AR',
                    intermediaryId: $scope.intermediaryId,
                    issuerId: $stateParams.issuerId,
                    issuerName:$scope.issuerName
                  };
                  // removing send email to intermediary code because this handled in service call
                  myVoteService.getIntermediaryDetails(
                    response.data.intermediaryVO.identificationNumber, 'attend').query().$promise
                    .then(
                      function(data) {
                        phoneNo = data.intermediaryPhone;
                        intermediaryName=  data.intermediaryName;
                        intEmailAdress = data.emailAddress;
                        //Modal window text is changed as part of SFCBP-1868
                        $scope.modalHeader = $filter('translate')(
                          'label_confirmVI_registration_header');
                        $scope.modalFooter = '';
                        $scope.modalLeftButton = $filter('translate')(
                          'label_ok');
                        $scope.modalRightButton = $filter('translate')('label_cancel');
                        if(data.agentAsIntermediay  === 'Y'){
                          $scope.modalBody = $filter('translate')(
                            'callIntermediary_SorryModalbody1')
                                                        + phoneNo + $filter('translate')(
                            'callIntermediary_SorryModalbody2');
                          $scope.modalBody1 = ' ';
                          $scope.modalBody2 = ' ';
                          $scope.modalBody3 = ' ';
                          $scope.modalBody4 = ' ';
                          $scope.modalBody5 = ' ';
                          $scope.modalBody6 = ' ';
                          $scope.modalBody7 = ' ';
                        }else{

                          $scope.modalBody = $filter('translate')(
                            'label_callIntermediary_AR_registration');
                          if(identificationNumber){
                            $scope.modalBody1 = $filter('translate')(
                              'label_ask_provideIdentification_VI')+ identificationNumber;
                          }
                          $scope.modalBody2 = $filter('translate')(
                            'label_sh_vi_modalBody2');
                          $scope.modalBody3 = $filter('translate')(
                            'label_sh_vi_modalBody3');
                          $scope.modalBody4 = $filter('translate')(
                            'lable_contactDetails')+$filter('translate')(
                            'label_colon');
                          if(intermediaryName){
                            $scope.modalBody5 =  $filter('translate')(
                              'label_intermediary')+$filter('translate')(
                              'label_colon')+' '+ intermediaryName;
                          }
                          if(intEmailAdress) {
                            $scope.modalBody6 = $filter('translate')(
                              'label_addresseeEmail')+$filter('translate')(
                              'label_colon')+' '+ intEmailAdress;
                          }
                          if(phoneNo) {
                            $scope.modalBody7 = $filter('translate')(
                              'label_telephoneNumber')+$filter('translate')(
                              'label_colon')+' '+ phoneNo;
                          }
                        }
                        $('#pop12').modal('show');
                      },function(error) {
                        $log.debug(error);
                      });
                }
              },
              function(error) {
                $scope.loadingSpin = false;
                $scope.errorCode = error.data.errors[0].errorCode;
                $scope.error = error.data.errors[0].errorMessage;
                //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                if(error.data.errors[0].errorCode === 'CBP_ERR_520' && ($scope.userType === 'SH' || $scope.userType === 'IN')){
                                        	if($scope.userType === 'IN'){  
	                                            $scope.EmailData = {
	                                                    votingInstType: 'attend',
	                                                    languageCode: $scope.languageCode,
	                                                    workStationType: $scope.userType,
	                                                    meetingId: $stateParams.gmId,
	                                                    shareClassId: $scope.selectedShareClass.shareclassId,
	                                                    intermediaryId: $scope.intermediaryId,
	                                                    issuerId: $stateParams.issuerId,
	                                                    loginUserId:$sessionStorage.userId
	                                            };
                                        	}else{
                                        		$scope.EmailData = {
	                                                    votingInstType: 'attend',
	                                                    languageCode: $scope.languageCode,
	                                                    workStationType: $scope.userType,
	                                                    meetingId: $stateParams.gmId,
	                                                    shareClassId: $scope.selectedShareClass.shareclassId,
	                                                    shareholderId: $sessionStorage.groupId,
	                                                    issuerId: $stateParams.issuerId,
	                                                    loginUserId:$sessionStorage.userId
	                                            };
                                        	}
                  voteInstructionService.sendSharesExceededMail($scope.EmailData,'secureToken').then(
                    function(response) {
                      $log.debug(response);
                    },
                    function(error) {
                      $log.debug(error);
                    });
                }
              });
        };
        //Below method is to route the 'i would like to vote for this meeting' button to respective pages, as part of SFCBP-1520
        $scope.votingInstructionCreation = function() {
          $scope.shareClassModelConfig = {};
          if ($scope.usertype === 'SP') {
            $scope.shareplanId = $sessionStorage.groupId;
          } else if ($scope.usertype === 'SH') {
            $scope.shareplanId = 0;
          } else {
            $scope.shareplanId = null;
          }
          voteInstructionService.getShareClassList($stateParams.gmId,
            $sessionStorage.groupId, $scope.languageCode, $scope.userType)
            .query().$promise.then(function(data) {
              if (data.length > 0) {
                $state.go('editGoToVotingInstructions', {
                  issuerName: $scope.issuerName,
                  meetingId: $stateParams.gmId,
                  issuerId: $scope.issuerId,
                  shareHolderId: $sessionStorage.groupId,
                  virtualMeetingFlag: $scope.isVirtualMeetingAllowed,
                  'selectedIndex': 1,
                  votingInstDetails: data
                });
              } else if(data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.votingInstructionCreation();
              } else {
                $scope.shareClassModelConfig.modalHeader = $filter(
                  'translate')('label_votingInsCreatErr');
                $scope.shareClassModelConfig.modalBody = $filter(
                  'translate')(
                  'label_voteNotPossible');
                ModelWindowService
                  .showModelWindow('shareClassAlertBox');
              }
            }, function(error) {
              return error;
            });
        };
        $scope.getSubmittedDetails();

        $scope.termsOfUseUrl = getTermsOfUseUrl('GM', $sessionStorage.workstationData.selectedLang);

        //Below function is for register virtual attendance request
        $scope.createVirtualParticipationRequest = function() {
          $state.go('vaRequestStep1',
            {
              selectedIndex: 0,
              issuerId: $stateParams.issuerId,
              gmId: $stateParams.gmId
            }, {
              location: true
            });
        };
      }]);
